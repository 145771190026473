<template>
<header class="bg-cover bg-bottom" :style="`background-image: url('${require('@/assets/img/bg.jpg')} ')`">
    <div class="min-h-screen w-full mx-auto flex justify-center items-end py-24 bg-gradient-to-b from-blue-700">
        <div class="text-white text-center px-12">
            <h1 class="font-body text-3xl lg:text-5xl pb-2 font-semibold leading-snug" v-html="$t('hero.title')"></h1>
            <img src="@/assets/img/hr.svg" :alt="$t('hero.title')" class="mx-auto h-6" />
            <p class="py-3 lg:w-3/4 mx-auto lg:text-xl" v-html="$t('hero.message')"></p>
            <div class="py-4 font-body text-xl lg:text-3xl italic">
                <q v-html="$t('hero.citation')"></q>
                <p v-html="$t('hero.autor')"></p>
            </div>
            <button v-scroll-to="'#goal'" class="bg-red-700 lg:my-2 py-3 px-4 shadow-lg hover:bg-orange-600" v-html="$t('hero.button')"></button>
        </div>
    </div>
    <div class="py-32 w-full px-8">
        <div class="mx-auto container text-center lg:text-xl">
            <p v-html="$t('hero.titleplace')"></p>
            <p v-html="$t('hero.place')" class="uppercase font-semibold"></p>
        </div>
    </div>
</header>
</template>

<script>
export default {
    name: "Hero",
};
</script>
